/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable react/no-array-index-key */
import React, { lazy, Suspense, Fragment } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import DashboardLayout from './layouts/DashboardLayout';
import LoadingScreen from './components/LoadingScreen';
import AuthGuard from './components/AuthGuard';
import AuthorityGuard from './components/AuthorityGuard';
import GuestGuard from './components/GuestGuard';

const routesConfig = [
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('./views/Error/Error404View'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/login',
    component: lazy(() => import('./views/Auth/LoginView'))
  },
  {
    path: '/',
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/',
        component: () => <Redirect to="/dashboard" />
      },
      {
        exact: true,
        path: '/account',
        component: lazy(() => import('./views/Account'))
      },
      {
        exact: true,
        path: '/dashboard',
        component: lazy(() => import('./views/Reports/DashboardView'))
      },
      {
        exact: true,
        path: '/members',
        authority: ['view-member', 'edit-member'],
        component: lazy(() => import('./views/Member/List'))
      },
      {
        exact: true,
        path: '/members/create',
        authority: ['edit-member'],
        component: lazy(() => import('./views/Member/Create'))
      },
      {
        exact: true,
        path: '/members/:id/edit',
        authority: ['edit-member'],
        component: lazy(() => import('./views/Member/Edit'))
      },
      {
        exact: true,
        path: '/members/:id/detail',
        authority: ['view-member', 'edit-member'],
        component: lazy(() => import('./views/Member/Detail'))
      },
      {
        exact: true,
        path: '/ranks',
        authority: ['view-rank', 'edit-rank'],
        component: lazy(() => import('./views/Rank/List'))
      },
      {
        exact: true,
        path: '/ranks/create',
        authority: ['edit-rank'],
        component: lazy(() => import('./views/Rank/Create'))
      },
      {
        exact: true,
        path: '/ranks/:id/edit',
        authority: ['edit-rank'],
        component: lazy(() => import('./views/Rank/Edit'))
      },
      {
        exact: true,
        path: '/ranks/:id/discount',
        authority: ['edit-rank'],
        component: lazy(() => import('./views/Rank/Discount'))
      },
      {
        exact: true,
        path: '/ranks/:id/upgradeSetting',
        authority: ['edit-rank'],
        component: lazy(() => import('./views/Rank/UpgradeSetting'))
      },
      {
        exact: true,
        path: '/ranks/expiredSetting',
        authority: ['edit-rank'],
        component: lazy(() => import('./views/Rank/ExpiredSetting'))
      },
      {
        exact: true,
        path: '/branches',
        authority: ['view-branch', 'edit-branch'],
        component: lazy(() => import('./views/Branch/List'))
      },
      {
        exact: true,
        path: '/branches/create',
        authority: ['edit-branch'],
        component: lazy(() => import('./views/Branch/Create'))
      },
      {
        exact: true,
        path: '/branches/:id/edit',
        authority: ['edit-branch'],
        component: lazy(() => import('./views/Branch/Edit'))
      },
      {
        exact: true,
        path: '/consumeChopRules',
        authority: ['view-rule', 'edit-rule'],
        component: lazy(() => import('./views/ConsumeChopRule/List'))
      },
      {
        exact: true,
        path: '/consumeChopRules/create',
        authority: ['edit-rule'],
        component: lazy(() => import('./views/ConsumeChopRule/Create'))
      },
      {
        exact: true,
        path: '/consumeChopRules/:id/edit',
        authority: ['edit-rule'],
        component: lazy(() => import('./views/ConsumeChopRule/Edit'))
      },
      {
        exact: true,
        path: '/earnChopRules',
        authority: ['view-rule', 'edit-rule'],
        component: lazy(() => import('./views/EarnChopRule/List'))
      },
      {
        exact: true,
        path: '/earnChopRules/create',
        authority: ['edit-rule'],
        component: lazy(() => import('./views/EarnChopRule/Create'))
      },
      {
        exact: true,
        path: '/earnChopRules/:id/edit',
        authority: ['edit-rule'],
        component: lazy(() => import('./views/EarnChopRule/Edit'))
      },
      {
        exact: true,
        path: '/customers',
        authority: ['super-admin'],
        component: lazy(() => import('./views/Customer/List'))
      },
      {
        exact: true,
        path: '/customers/create',
        authority: ['super-admin'],
        component: lazy(() => import('./views/Customer/Create'))
      },
      {
        exact: true,
        path: '/customers/:id/edit',
        authority: ['super-admin'],
        component: lazy(() => import('./views/Customer/Edit'))
      },
      {
        exact: true,
        path: '/chops/add',
        authority: ['edit-chops'],
        component: lazy(() => import('./views/Chops/AddChops'))
      },
      {
        exact: true,
        path: '/chops/expired',
        authority: ['edit-chops'],
        component: lazy(() => import('./views/Chops/ChopsExpiredSetting'))
      },
      {
        exact: true,
        path: '/prepaidcard/topup',
        authority: ['edit-prepaidcard'],
        component: lazy(() => import('./views/Prepaidcard/Topup'))
      },
      {
        exact: true,
        path: '/pickupCoupons',
        authority: ['view-pickup-coupon', 'edit-pickup-coupon'],
        component: lazy(() => import('./views/PickupCoupon/List'))
      },
      {
        exact: true,
        path: '/pickupCoupons/create',
        authority: ['edit-pickup-coupon'],
        component: lazy(() => import('./views/PickupCoupon/Create'))
      },
      {
        exact: true,
        path: '/pickupCoupons/:id/edit',
        authority: ['edit-pickup-coupon'],
        component: lazy(() => import('./views/PickupCoupon/Edit'))
      },
      {
        exact: true,
        path: '/pickupCoupons/:id/consume',
        authority: ['edit-pickup-coupon'],
        component: lazy(() => import('./views/PickupCoupon/Consume'))
      },
      {
        exact: true,
        path: '/promotions',
        authority: ['view-promotion', 'edit-promotion'],
        component: lazy(() => import('./views/Promotion/List'))
      },
      {
        exact: true,
        path: '/promotions/create',
        authority: ['edit-promotion'],
        component: lazy(() => import('./views/Promotion/Create'))
      },
      {
        exact: true,
        path: '/promotions/:id/edit',
        authority: ['edit-promotion'],
        component: lazy(() => import('./views/Promotion/Edit'))
      },
      {
        exact: true,
        path: '/registerChopRule',
        authority: ['edit-register-chop-rule'],
        component: lazy(() => import('./views/RegisterChopRule/Edit'))
      },
      {
        exact: true,
        path: '/users',
        authority: ['view-user', 'edit-user'],
        component: lazy(() => import('./views/User/List'))
      },
      {
        exact: true,
        path: '/users/create',
        authority: ['edit-user'],
        component: lazy(() => import('./views/User/Create'))
      },
      {
        exact: true,
        path: '/users/:id/edit',
        authority: ['edit-user'],
        component: lazy(() => import('./views/User/Edit'))
      },
      {
        exact: true,
        path: '/reports/prepaidcard/topup',
        authority: ['view-report'],
        component: lazy(() => import('./views/Reports/PrepaidcardTopup'))
      },
      {
        exact: true,
        path: '/reports/prepaidcard/payment',
        authority: ['view-report'],
        component: lazy(() => import('./views/Reports/PrepaidcardPayment'))
      },
      {
        exact: true,
        path: '/reports/chops/add',
        authority: ['view-report'],
        component: lazy(() => import('./views/Reports/ChopsAdd'))
      },
      {
        exact: true,
        path: '/reports/chops/consume',
        authority: ['view-report'],
        component: lazy(() => import('./views/Reports/ChopsConsume'))
      },
      {
        exact: true,
        path: '/reports/transactions',
        authority: ['view-report'],
        component: lazy(() => import('./views/Reports/Transaction'))
      },
      {
        exact: true,
        path: '/reports/memberRegisterBranch/detail',
        authority: ['view-report'],
        component: lazy(() =>
          import('./views/Reports/MemberRegisterBranchDetail')
        )
      },
      {
        exact: true,
        path: '/reports/memberRegisterBranch/statistics',
        authority: ['view-report'],
        component: lazy(() =>
          import('./views/Reports/MemberRegisterBranchStatistics')
        )
      },
      {
        exact: true,
        path: '/reports',
        component: () => <Redirect to="/reports/transactions" />
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  }
];

// eslint-disable-next-line no-confusing-arrow
const renderRoutes = (routes) =>
  routes ? (
    <Suspense fallback={<LoadingScreen />}>
      <Switch>
        {routes.map((route, i) => {
          const Guard = route.guard || Fragment;
          const Layout = route.layout || Fragment;
          const Component = route.component;
          const authority = route.authority || [];

          return (
            <Route
              key={i}
              path={route.path}
              exact={route.exact}
              render={(props) => (
                <Guard>
                  <AuthorityGuard authority={authority}>
                    <Layout>
                      {route.routes ? (
                        renderRoutes(route.routes)
                      ) : (
                        <Component {...props} />
                      )}
                    </Layout>
                  </AuthorityGuard>
                </Guard>
              )}
            />
          );
        })}
      </Switch>
    </Suspense>
  ) : null;

function Routes() {
  return renderRoutes(routesConfig);
}

export default Routes;
