const ZHCN = {
  // Login
  'Sign in': '登入',
  'Sign in on the member platform': '登入您的会员帐号',
  'Welcome to NoMember!': '感谢您使用 飨集点!',

  // NavBar
  Dashboard: 'Dashboard',
  'Members Management': '会员管理',
  Members: '会员',
  'Member List': '会员列表',
  Ranks: '会员等级',
  'Rank List': '会员等级列表',
  'Branches Management': '门店管理',
  Branches: '门店',
  'Branch List': '门店列表',
  'Chops Management': '点数管理',
  'Chop Rule': '点数规则',
  'Earn Chop Rule List': '累点规则列表',
  'Consume Chop Rule List': '兑点规则列表',
  'Add Chops': '手动加点',
  'Chops Expired Setting': '点数到期设定',
  'Prepaid Card Management': '储值卡管理',
  'Prepaid Card Topup': '储值卡加值',
  'Pickup Coupon List': '寄杯劵列表',
  'Promotion Management': '促销管理',
  Promotions: '促销',
  Promotion: '促销',
  'Promotion List': '促销列表',
  'Register Chop Rule Management': '註冊禮管理',
  'Register Chop Rules': '註冊禮',
  'Register Chop Rule': '註冊禮',
  'System Management': '系统管理',
  Users: '使用者',
  'User List': '使用者列表',
  'Customer Management': '客户管理',
  Customers: '客户',
  'Customer List': '客户列表',

  // TopBar
  Languages: '语言设定',
  Account: '我的帐号',
  Logout: '登出',

  // Branch
  Branch: '门店',
  'Branch Id': '门店编号',
  'Create Branch': '新增门店',
  'Edit Branch': '编辑门店',
  'Update Branch': '更新门店',
  'Register Member Count': '註册会员数',
  'Disable Consume Other Branch Chop': '禁止兌換其他店家的點數',

  // Consume Chop Rule
  'Consume Chop Rule': '兑点规则',
  'Consume Chop Rules': '兑点规则',
  'Edit Consume Chop Rules': '编辑兑点规则',
  'Create Consume Chop Rules': '新增兑点规则',
  'PaymentType.ALL': '不限制',
  'PaymentType.LINE_PAY': 'Line Pay',
  'PaymentType.CASH': '现金',
  'Type.AMOUNT': '总金额',
  'Type.ITEM_COUNT': '品项数',
  'ChopsType.EARN_CHOPS': '累积点数',
  'ChopsType.ADD_CHOPS': '手动加点',
  'ChopsType.CONSUME_CHOPS': '兑换点数',
  'ChopsType.VOID_EARN_CHOPS': '累积点数作废',
  'ChopsType.VOID_CONSUME_CHOPS': '兑换点数取消',

  // Earn Chop Rule
  'Earn Chop Rule': '累点规则',
  'Earn Chop Rules': '累点规则',
  'Edit Earn Chop Rules': '编辑累点规则',
  'Create Earn Chop Rules': '新增累点规则',

  // Prepaidcard
  'PrepaidcardsType.TOPUP': '储值',
  'PrepaidcardsType.PAYMENT': '付款',
  'PrepaidcardsType.VOID_PAYMENT': '付款作废',
  Balance: '储值卡馀额',
  Payment: '付款金额',

  // User
  User: '使用者',
  'Edit User': '编辑使用者',
  'Create User': '新增使用者',

  // Rank
  Rank: '会员等级',
  'Members Count': '会员数量',
  'Edit Rank': '编辑会员等级',
  'Create Rank': '新增会员等级',
  'Edit Rank Discount': '編輯會員折扣',
  'Is Active': '是否生效',

  // Member
  Member: '会员',
  'Edit Member': '编辑会员',
  'Create Member': '新增会员',
  'Member Detail': '会员详细资讯',
  'Member Information': '会员详细资讯',
  'Chops Detail': '点数详细资讯',
  'Order Records': '交易历史纪录',
  'Chop Records': '点数历史纪录',
  'Prepaidcard Records': '储值卡纪录',
  'Register Branch': '註册门市',
  'Non Register Branch': '无',

  // Customer
  'Edit Customer': '编辑客户',
  'Create Customer': '新增客户',
  Permissions: '权限',

  // Notification
  Updated: '{{ name }}资讯已更新',
  Created: '{{ name }}新增完成',
  'Chops added': '点数新增成功',
  'Setting updated': '设定更新完成',
  'Topup success': '储值成功',

  // Column
  Customer: '客户',
  'Email Address': '信箱/帐号',
  Password: '密码',
  Name: '名称',
  Code: '编号',
  'Store Name': '门店名称',
  Telephone: '电话',
  'Email address': 'Email',
  Fax: '传真',
  Note: '註记',
  Zipcode: '邮递区号',
  State: '省',
  City: '县市',
  County: '乡镇',
  Address: '地址',
  Latitude: '纬度',
  Longitude: '经度',
  Remark: '备註',
  'Opening Times': '营业时间',
  'Is Independent': '独立累点',
  Chops: '点数',
  Type: '类型',
  Description: '描述',
  'Payment Type': '付款别',
  'Chops Per Unit': '几点为一单位',
  'Unit Per Amount': '每单位价值',
  'Consume Max Percentage': '兑换最大百分比',
  'Activated At': '开始时间',
  'Expired At': '结束时间',
  'Earn Chops After Consume': '兑点后是否可以继续累点',
  'Rule Unit': '多少钱',
  'Rule Chops': '累计多少点',
  Phone: '电话号码',
  'First Name': '名',
  'Last Name': '姓',
  Gender: '性别',
  male: '男',
  female: '女',
  other: '其他',
  unknown: '未填写',
  Male: '男',
  Female: '女',
  Other: '其他',
  Unknown: '未填写',
  Total: '总计',
  'Order Id': '交易序号',
  Clerk: '店员',
  'Items Count': '品项数',
  Amount: '总计',
  'Transaction time': '交易时间',
  Status: '状态',
  Time: '时间',
  'Earn Chops': '累积点数',
  'Consume Chops': '兑换点数',
  Topup: '加值金额',
  Birthday: '生日',
  'Exclude Product': '排除产品',
  'Exclude Destination': '排除訂單類型',
  'Only Product': '仅限产品',
  'Card Carrier Number': '会员卡载具号码',
  'Invoice Carrier Number': '发票载具',

  // Chops Expired Settings
  'Expired Date': '到期日',
  'Expired after x days': '最后一次累积点数后x天到期',

  // Transaction
  'TransactionStatus.0': '已作废',
  'TransactionStatus.1': '正常',

  // Pickup Coupon
  'Pickup Coupon Management': '寄杯劵管理',
  'Pickup Coupon': '寄杯劵',
  'Create Pickup Coupon': '新增寄杯劵',
  'Edit Pickup Coupon': '修改寄杯劵',
  'Consume Pickup Coupon': '兑换寄杯劵',
  'Product Name': '产品名称',
  'Product No': '产品编号',
  Price: '价格',
  Quantity: '数量',
  'Consume Quantity': '兑换数量',
  'Consumed Quantity': '已兑换数量',
  Condiments: '调味',

  // Report
  Report: '报表',
  Reports: '统计',
  'Member Count': '新增会员数',
  'Total Chops': '总点数',
  'Total Earn Chops': '总累积点数',
  'Total Consume Chops': '总兑换点数',
  'Branch Count': '新增商家数',
  'Total Amount': '会员消费金额',
  'Rank Members': '会员等级占比',
  'Member Gender': '性别消费占比',
  'Branch Chops Summary': '店家累兑点',
  'New Old Member Summary': '新客/熟客消费占比',
  'Total Transaction Count': '总订单数',

  new_branch_register_member: '新客(今日註册)',
  old_branch_register_member: '旧客',
  earn_chop: '累点',
  consume_chop: '兑点',

  'Total Topup': '总储值卡加值金额',
  'Total Payment': '总储值卡付款金额',
  'Prepaidcard Topup Report': '储值卡加值报表',
  'Prepaidcard Payment Report': '储值卡付款报表',
  'Chops Add Report': '点数累积报表',
  'Chops Consume Report': '点数兑换报表',
  'Transaction Report': '会员消费报表',
  'Member Register Branch Detail': '会员註册门店明细',
  'Member Register Branch Statistics': '会员註册门店统计',

  memberCount: '新增会员趋势',
  branchCount: '新增店家趋势',
  earnChops: '累积点数趋势',
  consumeChops: '兑换点数趋势',
  transactionCount: '订单数趋势',
  transactionAmount: '会员消费趋势',
  topup: '储值卡加值趋势',
  payment: '储值卡付款趋势',

  // Delete
  'Delete Check': '删除确认',
  Delete: '删除',

  // Other
  'No Expired Time': '无期限',
  Valid: '有效',
  Invalid: '无效',

  // Action
  'Log In': '登入',
  Submit: '提交',
  Actions: '动作',
  Cancel: '取消',
  Save: '储存',

  // Weekday
  'weekDay.0': '星期日',
  'weekDay.1': '星期一',
  'weekDay.2': '星期二',
  'weekDay.3': '星期三',
  'weekDay.4': '星期四',
  'weekDay.5': '星期五',
  'weekDay.6': '星期六',

  // Promotion
  'Create Promotion': '新增促销',
  'Edit Promotion': '编辑促销',
  'Update Promotion': '更新促销',
  'Basic Information': '基本资料',
  'Promotion Name': '促销名称',
  'Promotion Code': '促销代码',
  Sequence: '排序',
  'Activated Date': '生效日期',
  'Activated Date Start': '生效起始日期',
  'Activated Date End': '生效结束日期',
  'Activated Time Start': '生效起始时间',
  'Activated Time End': '生效结束时间',
  'Activated Weekday': '生效日期(週)',
  'Activated Monthday': '生效日期(月)',
  'Activated Limit': '生效限制',
  'Limit Branch': '限制店家',
  'Limit Rank': '限制会员等级',
  'Can trigger others': '可触发后续促销',
  'Can trigger multiple': '可多次触发',
  'Trigger Condition Type': '触发条件',
  'Content Type': '折扣内容',
  'Apply Products List': '套用产品',
  Percentage: '百分比',

  // Register Chop Rule
  'Edit Register Chop Rule': '編輯註冊禮',

  SPECIFIC_PRODUCT_QTY: '特定品项满数量',
  TOTAL_TRANSACTION_QTY: '全单满数量',
  TOTAL_TRANSACTION_AMOUNT: '全单金额满额',

  DISCOUNT_AMOUNT: '折扣固定金额',
  DISCOUNT_PERCENTAGE: '折扣百分比',
  SPECIFIC_PRODUCT_DISCOUNT_AMOUNT: '特定品项折扣金额',
  SPECIFIC_PRODUCT_DISCOUNT_PERCENTAGE: '特定品项折扣百分比',
  SPECIFIC_FIXED_PRICE: '特定品项固定金额',
  FREE_SPECIFIC_PRODUCT: '赠送特定品项',

  No: '否',
  Yes: '是',

  // Valid
  'is required': '{{ name }} 为必填',
  'Must be a valid email': '必须为Email',
  'Must be at least 6 characters': '最少为六位英数字',

  // Message
  'The rank has members. Can not be deleted.': '此会员等级包含会员，无法删除',
  'You dont have permission to view this page.':
    '您无权限浏览此页面，如需使用此功能请联络您的经销商',

  // Hint
  'If empty means can trigger every day': '如留空代表每天皆可觸發'
};
export default ZHCN;
