import axios from 'axios';

const instance = axios.create({
  baseURL:
    process.env.NODE_ENV === 'production'
      ? process.env.REACT_APP_API_HOST || 'http://api.mbrtest.zoomye.com.tw'
      : 'http://127.0.0.1',
  withCredentials: true,
  headers: {
    'X-Customer-Account': localStorage.getItem('customer'),
    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
  }
});

export default instance;
