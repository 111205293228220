/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
import React, { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import {
  Box,
  Divider,
  Drawer,
  Hidden,
  List,
  ListSubheader,
  makeStyles
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import {
  ShoppingCart as ShoppingCartIcon,
  ShoppingBag as ShoppingBagIcon,
  CreditCard as CreditCardIcon,
  User as UserIcon,
  Sliders as SlidersIcon,
  PieChart as PieChartIcon,
  Users as UsersIcon,
  FileText as FileTextIcon,
  Sidebar as SidebarIcon
} from 'react-feather';
import useAuth from '../../../hooks/useAuth';
import { flattenAuthority } from '../../../utils/authority';
import { renderNavItems } from './MenuItem';

const navConfig = [
  {
    subheader: 'Dashboard',
    items: [
      {
        title: 'Dashboard',
        icon: PieChartIcon,
        href: '/dashboard'
      }
    ]
  },
  {
    subheader: 'Members Management',
    items: [
      {
        title: 'Members',
        icon: UsersIcon,
        href: '/members',
        items: [
          {
            authority: ['view-member', 'edit-member'],
            title: 'Member List',
            href: '/members'
          }
        ]
      },
      {
        title: 'Ranks',
        icon: CreditCardIcon,
        href: '/ranks',
        items: [
          {
            authority: ['view-rank', 'edit-rank'],
            title: 'Rank List',
            href: '/ranks'
          },
          {
            authority: ['edit-rank'],
            title: 'Rank Expired Setting',
            href: '/ranks/expiredSetting'
          }
        ]
      }
    ]
  },
  {
    subheader: 'Branches Management',
    items: [
      {
        title: 'Branches',
        icon: ShoppingCartIcon,
        href: '/branches',
        items: [
          {
            authority: ['view-branch', 'edit-branch'],
            title: 'Branch List',
            href: '/branches'
          }
        ]
      }
    ]
  },
  {
    subheader: 'Chops Management',
    items: [
      {
        title: 'Chop Rule',
        icon: ShoppingBagIcon,
        href: '/earnChopRules',
        items: [
          {
            authority: ['view-rule', 'edit-rule'],
            title: 'Earn Chop Rule List',
            href: '/earnChopRules'
          },
          {
            authority: ['view-rule', 'edit-rule'],
            title: 'Consume Chop Rule List',
            href: '/consumeChopRules'
          }
        ]
      },
      {
        title: 'Chops Management',
        icon: SlidersIcon,
        href: '/chops',
        items: [
          {
            authority: ['view-chops', 'edit-chops'],
            title: 'Add Chops',
            href: '/chops/add'
          },
          {
            authority: ['view-chops', 'edit-chops'],
            title: 'Chops Expired Setting',
            href: '/chops/expired'
          }
        ]
      }
    ]
  },
  {
    subheader: 'Prepaid Card Management',
    items: [
      {
        title: 'Prepaid Card Management',
        icon: CreditCardIcon,
        href: '/prepaidcard',
        items: [
          {
            authority: ['view-prepaidcard', 'edit-prepaidcard'],
            title: 'Prepaid Card Topup',
            href: '/prepaidcard/topup'
          }
        ]
      }
    ]
  },
  {
    subheader: 'Pickup Coupon Management',
    items: [
      {
        title: 'Pickup Coupon Management',
        icon: SidebarIcon,
        href: '/pickupCoupons',
        items: [
          {
            authority: ['view-pickup-coupon', 'edit-pickup-coupon'],
            title: 'Pickup Coupon',
            href: '/pickupCoupons'
          }
        ]
      }
    ]
  },
  {
    subheader: 'Promotion Management',
    items: [
      {
        title: 'Promotion Management',
        icon: SidebarIcon,
        href: '/promotions',
        items: [
          {
            authority: ['view-promotion', 'edit-promotion'],
            title: 'Promotion',
            href: '/promotions'
          }
        ]
      },
      {
        title: 'Register Chop Rule Management',
        icon: SidebarIcon,
        href: '/registerChopRule',
        items: [
          {
            authority: ['view-register-chop-rule', 'edit-register-chop-rule'],
            title: 'Register Chop Rule',
            href: '/registerChopRule'
          }
        ]
      }
    ]
  },
  {
    subheader: 'System Management',
    items: [
      {
        title: 'Users',
        icon: UserIcon,
        href: '/users',
        items: [
          {
            authority: ['view-user', 'edit-user'],
            title: 'User List',
            href: '/users'
          }
        ]
      }
    ]
  },
  {
    subheader: 'Customer Management',
    items: [
      {
        title: 'Customers',
        icon: UserIcon,
        href: '/customers',
        items: [
          {
            authority: ['super-admin'],
            title: 'Customer List',
            href: '/customers'
          }
        ]
      }
    ]
  },
  {
    subheader: 'Report',
    items: [
      {
        title: 'Report',
        icon: FileTextIcon,
        href: '/reports',
        items: [
          {
            authority: ['view-report'],
            title: 'Prepaidcard Topup Report',
            href: '/reports/prepaidcard/topup'
          },
          {
            authority: ['view-report'],
            title: 'Prepaidcard Payment Report',
            href: '/reports/prepaidcard/payment'
          },
          {
            authority: ['view-report'],
            title: 'Chops Add Report',
            href: '/reports/chops/add'
          },
          {
            authority: ['view-report'],
            title: 'Chops Consume Report',
            href: '/reports/chops/consume'
          },
          {
            authority: ['view-report'],
            title: 'Transaction Report',
            href: '/reports/transactions'
          },
          {
            authority: ['view-report'],
            title: 'Member Register Branch Detail',
            href: '/reports/memberRegisterBranch/detail'
          },
          {
            authority: ['view-report'],
            title: 'Member Register Branch Statistics',
            href: '/reports/memberRegisterBranch/statistics'
          }
        ]
      }
    ]
  }
];

const MenuList = ({ config }) => {
  const location = useLocation();
  const { hasPermission } = useAuth();
  const { t } = useTranslation();

  if (hasPermission([...flattenAuthority(config.items)])) {
    return (
      <List
        key={config.subheader}
        subheader={
          <ListSubheader disableGutters disableSticky>
            {t(config.subheader)}
          </ListSubheader>
        }
      >
        {renderNavItems({
          items: config.items,
          pathname: location.pathname
        })}
      </List>
    );
  }

  return <></>;
};

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  }
}));

function NavBar({ openMobile, onMobileClose }) {
  const classes = useStyles();
  const location = useLocation();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line
  }, [location.pathname]);

  const content = useMemo(
    () => (
      <Box height="100%" display="flex" flexDirection="column">
        <PerfectScrollbar options={{ suppressScrollX: true }}>
          <Box p={2}>
            {navConfig.map((config) => (
              <MenuList key={config.subheader} config={config} />
            ))}
          </Box>
          <Divider />
        </PerfectScrollbar>
      </Box>
    ),
    [navConfig]
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
}

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default NavBar;
