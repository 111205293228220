const ZHTW = {
  // Login
  'Sign in': '登入',
  'Sign in on the member platform': '登入您的會員帳號',
  'Welcome to NoMember!': '感謝您使用 饗集點!',

  // NavBar
  Dashboard: 'Dashboard',
  'Members Management': '會員管理',
  Members: '會員',
  'Member List': '會員列表',
  Ranks: '會員等級',
  'Rank List': '會員等級列表',
  'Branches Management': '門店管理',
  Branches: '門店',
  'Branch List': '門店列表',
  'Chops Management': '點數管理',
  'Chop Rule': '點數規則',
  'Earn Chop Rule List': '累點規則列表',
  'Consume Chop Rule List': '兌點規則列表',
  'Add Chops': '手動加點',
  'Chops Expired Setting': '點數到期設定',
  'Prepaid Card Management': '儲值卡管理',
  'Prepaid Card Topup': '儲值卡加值',
  'Pickup Coupon List': '寄杯劵列表',
  'Promotion Management': '促銷管理',
  Promotions: '促銷',
  Promotion: '促銷',
  'Promotion List': '促銷列表',
  'Register Chop Rule Management': '註冊禮管理',
  'Register Chop Rules': '註冊禮',
  'Register Chop Rule': '註冊禮',
  'System Management': '系統管理',
  Users: '使用者',
  'User List': '使用者列表',
  'Customer Management': '客戶管理',
  Customers: '客戶',
  'Customer List': '客戶列表',

  // TopBar
  Languages: '語言設定',
  Account: '我的帳號',
  Logout: '登出',

  // Branch
  Branch: '門店',
  'Branch Id': '門店編號',
  'Create Branch': '新增門店',
  'Edit Branch': '編輯門店',
  'Update Branch': '更新門店',
  'Register Member Count': '註冊會員數',
  'Disable Consume Other Branch Chop': '禁止兌換其他店家的點數',

  // Consume Chop Rule
  'Consume Chop Rule': '兌點規則',
  'Consume Chop Rules': '兌點規則',
  'Edit Consume Chop Rules': '編輯兌點規則',
  'Create Consume Chop Rules': '新增兌點規則',
  'PaymentType.ALL': '不限制',
  'PaymentType.LINE_PAY': 'Line Pay',
  'PaymentType.CASH': '現金',
  'Type.AMOUNT': '總金額',
  'Type.ITEM_COUNT': '品項數',
  'ChopsType.EARN_CHOPS': '累積點數',
  'ChopsType.ADD_CHOPS': '手動加點',
  'ChopsType.CONSUME_CHOPS': '兌換點數',
  'ChopsType.VOID_EARN_CHOPS': '累積點數作廢',
  'ChopsType.VOID_CONSUME_CHOPS': '兌換點數取消',

  // Earn Chop Rule
  'Earn Chop Rule': '累點規則',
  'Earn Chop Rules': '累點規則',
  'Edit Earn Chop Rules': '編輯累點規則',
  'Create Earn Chop Rules': '新增累點規則',

  // Prepaidcard
  'PrepaidcardsType.TOPUP': '儲值',
  'PrepaidcardsType.PAYMENT': '付款',
  'PrepaidcardsType.VOID_PAYMENT': '付款作廢',
  Balance: '儲值卡餘額',
  Payment: '付款金額',

  // User
  User: '使用者',
  'Edit User': '編輯使用者',
  'Create User': '新增使用者',

  // Rank
  Rank: '會員等級',
  'Members Count': '會員數量',
  'Edit Rank': '編輯會員等級',
  'Create Rank': '新增會員等級',
  'Edit Rank Discount': '編輯會員折扣',
  'Is Active': '是否生效',

  // Rank Upgrade Setting
  'Rank Upgrade Setting': '會員等級升級設定',
  'Edit Rank Upgrade Setting': '編輯會員等級升級設定',
  'Upgrade Setting': '升級設定',
  'Calculate Stanard': '計算標準',
  'Calculate Stanard Value': '計算標準值',
  'Calculate Time Unit': '計算時間單位',
  'Calculate Time Value': '計算時間值',
  AMOUNT: '消費金額',
  TIMES: '消費次數',
  CHOPS: '累積點數',
  DAY: '天',
  MONTH: '月',
  YEAR: '年',

  // Rank Expired Setting
  'Rank Expired Setting': '會員等級到期設定',

  // Member
  Member: '會員',
  'Edit Member': '編輯會員',
  'Create Member': '新增會員',
  'Member Detail': '會員詳細資訊',
  'Member Information': '會員詳細資訊',
  'Chops Detail': '點數詳細資訊',
  'Order Records': '交易歷史紀錄',
  'Chop Records': '點數歷史紀錄',
  'Prepaidcard Records': '儲值卡紀錄',
  'Register Branch': '註冊門市',
  'Non Register Branch': '無',

  // Customer
  'Edit Customer': '編輯客戶',
  'Create Customer': '新增客戶',
  Permissions: '權限',

  // Notification
  Updated: '{{ name }}資訊已更新',
  Created: '{{ name }}新增完成',
  'Chops added': '點數新增成功',
  'Setting updated': '設定更新完成',
  'Topup success': '儲值成功',

  // Column
  Customer: '客戶',
  'Email Address': '信箱/帳號',
  Password: '密碼',
  Name: '名稱',
  Code: '編號',
  'Store Name': '門店名稱',
  Telephone: '電話',
  'Email address': 'Email',
  Fax: '傳真',
  Note: '註記',
  Zipcode: '郵遞區號',
  State: '省',
  City: '縣市',
  County: '鄉鎮',
  Address: '地址',
  Latitude: '緯度',
  Longitude: '經度',
  Remark: '備註',
  'Opening Times': '營業時間',
  'Is Independent': '獨立累點',
  Chops: '點數',
  Type: '類型',
  Description: '描述',
  'Payment Type': '付款別',
  'Chops Per Unit': '幾點為一單位',
  'Unit Per Amount': '每單位價值',
  'Consume Max Percentage': '兌換最大百分比',
  'Activated At': '開始時間',
  'Expired At': '結束時間',
  'Earn Chops After Consume': '兌點後是否可以繼續累點',
  'Rule Unit': '多少錢',
  'Rule Chops': '累計多少點',
  Phone: '電話號碼',
  'First Name': '名',
  'Last Name': '姓',
  Gender: '性別',
  male: '男',
  female: '女',
  other: '其他',
  unknown: '未填寫',
  Male: '男',
  Female: '女',
  Other: '其他',
  Unknown: '未填寫',
  Total: '總計',
  'Order Id': '交易序號',
  Clerk: '店員',
  'Items Count': '品項數',
  Amount: '總計',
  'Transaction time': '交易時間',
  Status: '狀態',
  Time: '時間',
  'Earn Chops': '累積點數',
  'Consume Chops': '兌換點數',
  Topup: '加值金額',
  Birthday: '生日',
  'Exclude Product': '排除產品',
  'Exclude Destination': '排除訂單類型',
  'Only Product': '僅限產品',
  'Card Carrier Number': '會員卡載具號碼',
  'Invoice Carrier Number': '發票載具',

  // Chops Expired Settings
  'Expired Date': '到期日',
  'Expired after x days': '最後一次累積點數後x天到期',
  Expired: '到期',

  // Transaction
  'TransactionStatus.0': '已作廢',
  'TransactionStatus.1': '正常',

  // Pickup Coupon
  'Pickup Coupon Management': '寄杯劵管理',
  'Pickup Coupon': '寄杯劵',
  'Create Pickup Coupon': '新增寄杯劵',
  'Edit Pickup Coupon': '修改寄杯劵',
  'Consume Pickup Coupon': '兌換寄杯劵',
  'Product Name': '產品名稱',
  'Product No': '產品編號',
  Price: '價格',
  Quantity: '數量',
  'Consume Quantity': '兌換數量',
  'Consumed Quantity': '已兌換數量',
  Condiments: '調味',

  // Report
  Report: '報表',
  Reports: '統計',
  'Member Count': '新增會員數',
  'Total Chops': '總點數',
  'Total Earn Chops': '總累積點數',
  'Total Consume Chops': '總兌換點數',
  'Branch Count': '新增商家數',
  'Total Amount': '會員消費金額',
  'Rank Members': '會員等級占比',
  'Member Gender': '性別消費占比',
  'Branch Chops Summary': '店家累兌點',
  'New Old Member Summary': '新客/熟客消費占比',
  'Total Transaction Count': '總訂單數',

  new_branch_register_member: '新客(今日註冊)',
  old_branch_register_member: '舊客',
  earn_chop: '累點',
  consume_chop: '兌點',

  'Total Topup': '總儲值卡加值金額',
  'Total Payment': '總儲值卡付款金額',
  'Prepaidcard Topup Report': '儲值卡加值報表',
  'Prepaidcard Payment Report': '儲值卡付款報表',
  'Chops Add Report': '點數累積報表',
  'Chops Consume Report': '點數兌換報表',
  'Transaction Report': '會員消費報表',
  'Member Register Branch Detail': '會員註冊門店明細',
  'Member Register Branch Statistics': '會員註冊門店統計',

  memberCount: '新增會員趨勢',
  branchCount: '新增店家趨勢',
  earnChops: '累積點數趨勢',
  consumeChops: '兌換點數趨勢',
  transactionCount: '訂單數趨勢',
  transactionAmount: '會員消費趨勢',
  topup: '儲值卡加值趨勢',
  payment: '儲值卡付款趨勢',

  // Delete
  'Delete Check': '刪除確認',
  Delete: '刪除',

  // Other
  'No Expired Time': '無期限',
  Valid: '有效',
  Invalid: '無效',

  // Action
  'Log In': '登入',
  Submit: '提交',
  Actions: '動作',
  Cancel: '取消',
  Save: '儲存',

  // Weekday
  'weekDay.0': '星期日',
  'weekDay.1': '星期一',
  'weekDay.2': '星期二',
  'weekDay.3': '星期三',
  'weekDay.4': '星期四',
  'weekDay.5': '星期五',
  'weekDay.6': '星期六',
  'weekDay.7': '星期日',

  // Promotion
  'Create Promotion': '新增促銷',
  'Edit Promotion': '編輯促銷',
  'Update Promotion': '更新促銷',
  'Basic Information': '基本資料',
  'Promotion Name': '促銷名稱',
  'Promotion Code': '促銷代碼',
  Sequence: '排序',
  'Activated Date': '生效日期',
  'Activated Date Start': '生效起始日期',
  'Activated Date End': '生效結束日期',
  'Activated Time Start': '生效起始時間',
  'Activated Time End': '生效結束時間',
  'Activated Weekday': '生效日期(週)',
  'Activated Monthday': '生效日期(月)',
  'Activated Limit': '生效限制',
  'Limit Branch': '限制店家',
  'Limit Rank': '限制會員等級',
  'Can trigger others': '可觸發後續促銷',
  'Can trigger multiple': '可多次觸發',
  'Trigger Condition Type': '觸發條件',
  'Content Type': '折扣內容',
  'Apply Products List': '套用產品',
  Percentage: '百分比',

  // Register Chop Rule
  'Edit Register Chop Rule': '編輯註冊禮',

  SPECIFIC_PRODUCT_QTY: '特定品項滿數量',
  TOTAL_TRANSACTION_QTY: '全單滿數量',
  TOTAL_TRANSACTION_AMOUNT: '全單金額滿額',

  DISCOUNT_AMOUNT: '折扣固定金額',
  DISCOUNT_PERCENTAGE: '折扣百分比',
  SPECIFIC_PRODUCT_DISCOUNT_AMOUNT: '特定品項折扣金額',
  SPECIFIC_PRODUCT_DISCOUNT_PERCENTAGE: '特定品項折扣百分比',
  SPECIFIC_FIXED_PRICE: '特定品項固定金額',
  FREE_SPECIFIC_PRODUCT: '贈送特定品項',

  No: '否',
  Yes: '是',

  // Valid
  'is required': '{{ name }} 為必填',
  'Must be a valid email': '必須為Email',
  'Must be at least 6 characters': '最少為六位英數字',

  // Message
  'The rank has members. Can not be deleted.': '此會員等級包含會員，無法刪除',
  'You dont have permission to view this page.':
    '您無權限瀏覽此頁面，如需使用此功能請聯絡您的經銷商',

  // Hint
  'If empty means can trigger every day': '如留空代表每天皆可觸發'
};
export default ZHTW;
