const EN = {
  // Login
  'Sign in': 'Sign in',
  'Sign in on the member platform': 'Sign in on the member platform',
  'Welcome to NoMember!': 'Welcome to NoMember!',

  // NavBar
  Dashboard: 'Dashboard',
  'Members Management': 'Members Management',
  Members: 'Members',
  'Member List': 'Member List',
  Ranks: 'Ranks',
  'Rank List': 'Rank List',
  'Branches Management': 'Branches Management',
  Branches: 'Branches',
  'Branch List': 'Branch List',
  'Chops Management': 'Chops Management',
  'Chop Rule': 'Chop Rule',
  'Earn Chop Rule List': 'Earn Chop Rule List',
  'Consume Chop Rule List': 'Consume Chop Rule List',
  'Add Chops': 'Add Chops',
  'Chops Expired Setting': 'Chops Expired Setting',
  'Prepaid Card Management': 'Prepaid Card Management',
  'Prepaid Card Topup': 'Prepaid Card Topup',
  'Pickup Coupon List': 'Pickup Coupon List',
  'Promotion Management': 'Promotion Management',
  Promotions: 'Promotions',
  Promotion: 'Promotion',
  'Promotion List': 'Promotion List',
  'Register Chop Rule Management': 'Register Chop Rule',
  'Register Chop Rules': 'Register Chop Rules',
  'Register Chop Rule': 'Register Chop Rule',
  'System Management': 'System Management',
  Users: 'Users',
  'User List': 'User List',
  'Customer Management': 'Customer Management',
  Customers: 'Customers',
  'Customer List': 'Customer List',

  // TopBar
  Languages: 'Languages',
  Account: 'Account',
  Logout: 'Logout',

  // Branch
  Branch: 'Branch',
  'Branch Id': 'Branch Id',
  'Create Branch': 'Create Branch',
  'Edit Branch': 'Edit Branch',
  'Update Branch': 'Update Branch',
  'Register Member Count': 'Register Member Count',
  'Disable Consume Other Branch Chop': 'Disable Consume Other Branch Chop',

  // Consume Chop Rule
  'Consume Chop Rule': 'Consume Chop Rule',
  'Consume Chop Rules': 'Consume Chop Rules',
  'Edit Consume Chop Rules': 'Edit Consume Chop Rules',
  'Create Consume Chop Rules': 'Create Consume Chop Rules',
  'PaymentType.ALL': 'All',
  'PaymentType.LINE_PAY': 'Line Pay',
  'PaymentType.CASH': 'Cash',
  'Type.AMOUNT': 'Amount',
  'Type.ITEM_COUNT': 'Item Count',
  'ChopsType.EARN_CHOPS': 'Earn Chops',
  'ChopsType.ADD_CHOPS': 'Add Chops',
  'ChopsType.CONSUME_CHOPS': 'Consume Chops',
  'ChopsType.VOID_EARN_CHOPS': 'Void Earn Chops',
  'ChopsType.VOID_CONSUME_CHOPS': 'Void Consume Chops',

  // Earn Chop Rule
  'Earn Chop Rule': 'Earn Chop Rule',
  'Earn Chop Rules': 'Earn Chop Rules',
  'Edit Earn Chop Rules': 'Edit Earn Chop Rules',
  'Create Earn Chop Rules': 'Create Earn Chop Rules',

  // Prepaidcard
  'PrepaidcardsType.TOPUP': 'Topup',
  'PrepaidcardsType.PAYMENT': 'Payment',
  'PrepaidcardsType.VOID_PAYMENT': 'Void Payment',
  Balance: 'Balance',
  Payment: 'Payment',

  // User
  User: 'User',
  'Edit User': 'Edit User',
  'Create User': 'Create User',

  // Rank
  Rank: 'Rank',
  'Members Count': 'Members Count',
  'Edit Rank': 'Edit Rank',
  'Create Rank': 'Create Rank',
  'Edit Rank Discount': 'Edit Rank Discount',
  'Is Active': 'Is Active',

  // Rank Upgrade Setting
  'Rank Upgrade Setting': 'Rank Upgrade Setting',
  'Edit Rank Upgrade Setting': 'Edit Rank Upgrade Setting',
  'Upgrade Setting': 'Upgrade Setting',
  'Calculate Stanard': 'Calculate Stanard',
  'Calculate Stanard Value': 'Calculate Stanard Value',
  'Calculate Time Unit': 'Calculate Time Unit',
  'Calculate Time Value': 'Calculate Time Value',
  AMOUNT: 'Transaction Amount',
  TIMES: 'Transaction Times',
  CHOPS: 'Earn Chops',
  DAY: 'Day',
  MONTH: 'Month',
  YEAR: 'Year',

  // Rank Expired Setting
  'Rank Expired Setting': '會員等級到期設定',

  // Member
  Member: 'Member',
  'Edit Member': 'Edit Member',
  'Create Member': 'Create Member',
  'Member Detail': 'Member Detail',
  'Member Information': 'Member Information',
  'Chops Detail': 'Chops Detail',
  'Order Records': 'Order Records',
  'Chop Records': 'Chop Records',
  'Prepaidcard Records': 'Prepaidcard Records',
  'Register Branch': 'Register Branch',
  'Non Register Branch': 'Non Register Branch',

  // Customer
  'Edit Customer': 'Edit Customer',
  'Create Customer': 'Create Customer',
  Permissions: 'Permissions',

  // Notification
  Updated: '{{ name }} Updated',
  Created: '{{ name }} Created',
  'Chops added': 'Chops added',
  'Setting updated': 'Setting updated',
  'Topup success': 'Topup success',

  // Column
  Customer: 'Customer',
  'Email Address/Account': 'Email Address/Account',
  Password: 'Password',
  Name: 'Name',
  Code: 'Code',
  'Store Name': 'Store Name',
  Telephone: 'Telephone',
  'Email address': 'Email address',
  Fax: 'Fax',
  Note: 'Note',
  Zipcode: 'Zipcode',
  State: 'State',
  City: 'City',
  County: 'County',
  Address: 'Address',
  Latitude: 'Latitude',
  Longitude: 'Longitude',
  Remark: 'Remark',
  'Opening Times': 'Opening Times',
  'Is Independent': 'Is Independent',
  Chops: 'Chops',
  Type: 'Type',
  Description: 'Description',
  'Payment Type': 'Payment Type',
  'Chops Per Unit': 'Chops Per Unit',
  'Unit Per Amount': 'Unit Per Amount',
  'Consume Max Percentage': 'Consume Max Percentage',
  'Activated At': 'Activated At',
  'Expired At': 'Expired At',
  'Earn Chops After Consume': 'Earn Chops After Consume',
  'Rule Unit': 'Rule Unit',
  'Rule Chops': 'Rule Chops',
  Phone: 'Phone',
  'First Name': 'First Name',
  'Last Name': 'Last Name',
  Gender: 'Gender',
  male: 'Male',
  female: 'Female',
  other: 'Other',
  unknown: 'Unknown',
  Male: 'Male',
  Female: 'Female',
  Other: 'Other',
  Unknown: 'Unknown',
  Total: 'Total',
  'Order Id': 'Order Id',
  Clerk: 'Clerk',
  'Items Count': 'Items Count',
  Amount: 'Amount',
  'Transaction time': 'Transaction time',
  Status: 'Status',
  Time: 'Time',
  'Earn Chops': 'Earn Chops',
  'Consume Chops': 'Consume Chops',
  Topup: 'Topup',
  Birthday: 'Birthday',
  'Exclude Product': 'Exclude Products',
  'Exclude Destination': 'Exclude Destinations',
  'Only Product': 'Only Products',
  'Card Carrier Number': 'Card Carrier Number',
  'Invoice Carrier Number': 'Invoice Carrier Number',

  // Chops Expired Settings
  'Expired Date': 'Expired Date',
  'Expired after x days': 'Expired after x days',

  // Transaction
  'TransactionStatus.0': 'Voided',
  'TransactionStatus.1': 'Normal',

  // Pickup Coupon
  'Pickup Coupon Management': 'Pickup Coupon Management',
  'Pickup Coupon': 'Pickup Coupon',
  'Create Pickup Coupon': 'Create Pickup Coupon',
  'Edit Pickup Coupon': 'Edit Pickup Coupon',
  'Consume Pickup Coupon': 'Consume Pickup Coupon',
  'Product Name': 'Product Name',
  'Product No': 'Product No',
  Price: 'Price',
  Quantity: 'Quantity',
  'Consumed Quantity': 'Consumed Quantity',
  Condiments: 'Condiments',

  // Report
  Report: 'Report',
  Reports: 'Reports',
  'Member Count': 'Member Count',
  'Total Chops': 'Total Chops',
  'Total Earn Chops': 'Total Earn Chops',
  'Total Consume Chops': 'Total Consume Chops',
  'Branch Count': 'Branch Count',
  'Total Amount': 'Total Amount',
  'Rank Members': 'Rank Members',
  'Branch Chops Summary': 'Branch Chops Summary',
  'New Old Member Summary': 'New/Old Member Summary',
  'Total Transaction Count': 'Total Transaction Count',

  new_branch_register_member: 'New Member',
  old_branch_register_member: 'Old Member',
  earn_chop: 'Earn Point',
  consume_chop: 'Consume Point',

  'Total Topup': 'Total Topup',
  'Total Payment': 'Total Payment',
  'Prepaidcard Topup Report': 'Prepaidcard Topup Report',
  'Prepaidcard Payment Report': 'Prepaidcard Payment Report',
  'Chops Add Report': 'Chops Add Report',
  'Chops Consume Report': 'Chops Consume Report',
  'Transaction Report': 'Transaction Report',
  'Member Register Branch Detail': 'Member Register Branch Detail',
  'Member Register Branch Statistics': 'Member Register Branch Statistics',

  memberCount: 'New Member Trend',
  branchCount: 'New Branch Trend',
  earnChops: 'Earn Point Trend',
  consumeChops: 'Consume Point Trend',
  transactionCount: 'Transaction Count Trend',
  transactionAmount: 'Transaction Amount Trend',
  topup: 'Prepaidcard Topup Trend',
  payment: 'Prepaidcard Payment Trend',

  // Delete
  'Delete Check': 'Delete Check',
  Delete: 'Delete',

  // Other
  'No Expired Time': 'No Expired Time',
  Valid: 'Valid',
  Invalid: 'Invalid',

  // Action
  'Log In': 'Log In',
  Submit: 'Submit',
  Actions: 'Action',
  Cancel: 'Cancel',
  Save: 'Save',

  // Weekday
  'weekDay.0': 'Sunday',
  'weekDay.1': 'Monday',
  'weekDay.2': 'Tuesday',
  'weekDay.3': 'Wednesday',
  'weekDay.4': 'Thursday',
  'weekDay.5': 'Friday',
  'weekDay.6': 'Saturday',

  // Promotion
  'Create Promotion': 'Create Promotion',
  'Edit Promotion': 'Edit Promotion',
  'Update Promotion': 'Update Promotion',
  'Basic Information': 'Basic Information',
  'Promotion Name': '促銷Promotion Name名稱',
  'Promotion Code': 'Promotion Code',
  Sequence: 'Sequence',
  'Activated Date': 'Activated Date',
  'Activated Date Start': 'Activated Date Start',
  'Activated Date End': 'Activated Date End',
  'Activated Time Start': 'Activated Time Start',
  'Activated Time End': 'Activated Time End',
  'Activated Weekday': 'Activated Weekday',
  'Activated Monthday': 'Activated Monthday',
  'Activated Limit': 'Activated Limit',
  'Limit Branch': 'Limit Branch',
  'Limit Rank': 'Limit Rank',
  'Can trigger others': 'Can trigger others',
  'Can trigger multiple': 'Can trigger multiple',
  'Trigger Condition Type': 'Trigger Condition Type',
  'Content Type': 'Content Type',
  'Apply Products List': 'Apply Products List',
  Percentage: 'Percentage',

  // Register Chop Rule
  'Edit Register Chop Rule': 'Edit Register Chop Rule',

  SPECIFIC_PRODUCT_QTY: 'Specific Product Quantity',
  TOTAL_TRANSACTION_QTY: 'Total Transaction Quantity',
  TOTAL_TRANSACTION_AMOUNT: 'Total Transaction Amount',

  DISCOUNT_AMOUNT: 'Discount Amount',
  DISCOUNT_PERCENTAGE: 'Discount Percentage',
  SPECIFIC_PRODUCT_DISCOUNT_AMOUNT: 'Specific Product Discount Amount',
  SPECIFIC_PRODUCT_DISCOUNT_PERCENTAGE: 'Specific Product Discount Percentage',
  SPECIFIC_FIXED_PRICE: 'Specific Fixed Price',
  FREE_SPECIFIC_PRODUCT: 'Free Specific Product',

  No: 'No',
  Yes: 'Yes',

  // Valid
  'is required': '{{ name }} is required',
  'Must be a valid email': 'Must be a valid email',
  'Must be at least 6 characters': 'Must be at least 6 characters',

  // Message
  'The rank has members. Can not be deleted.':
    'The rank has members. Can not be deleted.',
  'You dont have permission to view this page.':
    'You dont have permission to view this page. Please contact your administrator',

  // Hint
  'If empty means can trigger every day': 'If empty means can trigger every day'
};

export default EN;
