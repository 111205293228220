import { useMemo, useCallback } from 'react';
import { useSelector } from 'react-redux';

const useAuth = () => {
  const { user } = useSelector((state) => state.account);
  const permissionArray = useMemo(
    () => (user ? user.permissions.map((permission) => permission.name) : []),
    [user]
  );

  const isSuperAdmin = useMemo(
    () => (permissionArray.find((permission) => permission === 'super-admin') ? true : false),
    [permissionArray]
  );

  const hasPermission = useCallback(
    (permissions) => {
      if (isSuperAdmin) {
        return true;
      }

      if (permissions.length === 0) {
        return true;
      }

      if (permissionArray.some((item) => permissions.includes(item))) {
        return true;
      }

      return false;
    },
    [permissionArray]
  );

  return {
    user,
    permissions: permissionArray,
    isSuperAdmin,
    hasPermission
  };
};

export default useAuth;
