// eslint-disable-next-line import/prefer-default-export
export const flattenAuthority = (items) => {
  let authorities = new Set([]);

  if (items) {
    items.map((item) => {
      if (item.authority) {
        authorities = new Set([...authorities, ...item.authority]);
      }
      let subAuthority = [];
      if (item.items) {
        subAuthority = flattenAuthority(item.items);
      }
      authorities = new Set([...authorities, ...subAuthority]);
    });
  }

  return authorities;
};
