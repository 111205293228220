import axios from '../utils/axios';

class UserService {
  list = async () => {
    const users = await axios.get('/api/v1/users');
    return users;
  };

  create = async (data) => {
    const user = await axios.post('/api/v1/users', data);
    return user;
  };

  get = async (id) => {
    const user = await axios.get(`/api/v1/users/${id}`);
    return user;
  };

  update = async (id, data) => {
    const user = await axios.patch(`/api/v1/users/${id}`, data);
    return user;
  };

  delete = async (id) => {
    const user = await axios.delete(`/api/v1/users/${id}`);
    return user;
  };
}

const userService = new UserService();

export default userService;
