import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import useAuth from '../hooks/useAuth';
import NoMatch from './NoMatch';

function AuthorityGuard({ children, authority }) {
  const { isSuperAdmin, hasPermission } = useAuth();
  const { t } = useTranslation();

  if (isSuperAdmin) {
    return children;
  }

  if (hasPermission(authority)) {
    return children;
  }

  return <NoMatch title={t('You dont have permission to view this page.')} />;
}

AuthorityGuard.propTypes = {
  children: PropTypes.any,
  authority: PropTypes.array.isRequired
};

export default AuthorityGuard;
