import React, { useRef, useState, useMemo } from 'react';
import {
  Box,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Popover,
  Tooltip,
  Typography,
  makeStyles
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import ReactCountryFlag from 'react-country-flag';

const useStyles = makeStyles((theme) => ({
  popover: {
    width: 320,
    padding: theme.spacing(2)
  },
  list: {
    padding: theme.spacing(1, 3)
  },
  listItemText: {
    marginRight: theme.spacing(1)
  },
  lastActivity: {
    whiteSpace: 'nowrap'
  }
}));

const languages = [
  {
    name: 'English',
    abbreviation: 'EN',
    key: 'en',
    code: 'US'
  },
  {
    name: '繁體中文',
    abbreviation: '繁中',
    key: 'zh-TW',
    code: 'TW'
  },
  {
    name: '简体中文',
    abbreviation: '简中',
    key: 'zh-CN',
    code: 'CN'
  }
];

function Languages() {
  const classes = useStyles();
  const ref = useRef(null);
  const { t, i18n } = useTranslation();
  const [isOpen, setOpen] = useState(false);
  const currentLanguage = useMemo(
    () => languages.find((language) => language.key === i18n.language) || languages[0],
    [languages, i18n.language]
  );

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Tooltip title="Languages">
        <IconButton color="inherit" onClick={handleOpen} ref={ref}>
          {/* <ReactCountryFlag countryCode={currentLanguage.code} svg /> */}
          <Typography variant="h6">{currentLanguage.abbreviation}</Typography>
        </IconButton>
      </Tooltip>
      <Popover
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        classes={{ paper: classes.popover }}
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
      >
        <Typography variant="h4" color="textPrimary">
          {t('Languages')}
        </Typography>
        <Box mt={2}>
          <List disablePadding>
            {languages.map((language) => (
              <ListItem
                disableGutters
                key={language.key}
                onClick={() => changeLanguage(language.key)}
              >
                <ListItemAvatar>
                  <ReactCountryFlag
                    countryCode={language.code}
                    style={{
                      width: '2em',
                      height: '2em'
                    }}
                    svg
                  />
                </ListItemAvatar>
                <ListItemText className={classes.listItemText} primary={language.name} />
              </ListItem>
            ))}
          </List>
        </Box>
      </Popover>
    </>
  );
}

export default Languages;
