import jwtDecode from 'jwt-decode';
import axios from '../utils/axios';

class AuthService {
  setAxiosInterceptors = ({ onLogout }) => {
    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response && error.response.status === 401) {
          this.setSession(null);

          if (onLogout) {
            onLogout();
          }
        }

        return Promise.reject(error);
      }
    );
  };

  handleAuthentication() {
    const accessToken = this.getAccessToken();

    if (!accessToken) {
      return;
    }

    if (this.isValidToken(accessToken)) {
      this.setSession(accessToken);
    } else {
      this.setSession(null);
    }
  }

  loginWithEmailAndPassword = async (customer, email, password) => {
    const response = await axios.post('/api/v1/auth/login', {
      customer,
      email,
      password
    });
    if (response.data.data.token) {
      this.setSession(response.data.data.token);
      this.setCustomer(customer);
    } else {
      throw new Error(response.data.message);
    }
    return response;
  };

  getUserByToken = async () => {
    const response = await axios.get('/api/v1/user/me');
    if (!response.data.data) {
      throw new Error(response.data.message);
    }
    return response.data.data;
  };

  loginInWithToken = async () => {
    const response = await axios.get('/api/v1/user/me');
    if (!response.data.data) {
      throw new Error(response.data.message);
    }
    return response.data.data;
  };

  logout = () => {
    this.setSession(null);
    this.setCustomer(null);
  };

  setSession = (accessToken) => {
    if (accessToken) {
      localStorage.setItem('accessToken', accessToken);
      axios.defaults.headers.Authorization = `Bearer ${accessToken}`;
    } else {
      localStorage.removeItem('accessToken');
      delete axios.defaults.headers.Authorization;
    }
  };

  setCustomer = (customer) => {
    if (customer) {
      localStorage.setItem('customer', customer);
      axios.defaults.headers['X-Customer-Account'] = `${customer}`;
    } else {
      localStorage.removeItem('customer');
      delete axios.defaults.headers['X-Customer-Account'];
    }
  };

  getAccessToken = () => localStorage.getItem('accessToken');

  isValidToken = (accessToken) => {
    if (!accessToken) {
      return false;
    }

    const decoded = jwtDecode(accessToken);
    const currentTime = Date.now() / 1000;

    return decoded.exp > currentTime;
  };

  isAuthenticated = () => !!this.getAccessToken();
}

const authService = new AuthService();

export default authService;
