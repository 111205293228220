import React from 'react';
import { List } from '@material-ui/core';
import { matchPath } from 'react-router';
import NavItem from './NavItem';
import { flattenAuthority } from '../../../utils/authority';
import useAuth from '../../../hooks/useAuth';

export const renderNavItems = ({ items, ...rest }) => {
  return (
    <List disablePadding>
      {items.map((item) => (
        <MenuItem key={item.title + item.depth} item={item} {...rest} />
      ))}
    </List>
  );
};

const MenuItem = ({ pathname, item, depth = 0 }) => {
  const { hasPermission } = useAuth();
  const key = item.title + depth;

  if (!hasPermission([...flattenAuthority(item.items)])) {
    return <></>;
  }

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false
    });

    return (
      <NavItem
        depth={depth}
        icon={item.icon}
        key={key}
        info={item.info}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items
        })}
      </NavItem>
    );
  }
  return (
    <NavItem
      depth={depth}
      href={item.href}
      icon={item.icon}
      key={key}
      info={item.info}
      title={item.title}
    />
  );
};

export default MenuItem;
