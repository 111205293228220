import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import en from './locale/en';
import zhTW from './locale/zh-TW';
import zhCN from './locale/zh-CN';

// the translations
const resources = {
  en: {
    translation: en
  },
  'zh-TW': {
    translation: zhTW
  },
  'zh-CN': {
    translation: zhCN
  }
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    fallbackLng: 'zh-TW',

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;
