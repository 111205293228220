import React from 'react';
import PropTypes from 'prop-types';
import { Typography, makeStyles } from '@material-ui/core';
import grey from '@material-ui/core/colors/grey';
import astronaut from '../assets/images/astronaut.svg';

const useStyles = makeStyles(() => ({
  title: {
    textAlign: 'center',
    color: grey[900]
  },
  image: {
    top: '50px',
    width: '100%',
    textAlign: 'center'
  }
}));

const NoMatch = ({ title }) => {
  const classes = useStyles();

  return (
    <div>
      <Typography
        className={classes.title}
        variant="h2"
        component="h2"
        display="block"
        gutterBottom
      >
        {title}
      </Typography>
      <div className={classes.image}>
        <img width={512} src={astronaut} alt="no-match" />
      </div>
    </div>
  );
};

NoMatch.propTypes = {
  title: PropTypes.string
};

export default NoMatch;
